@import '../Summary/_Summary.scss';


.summary-tiles {
    @include FlexRowLeft();
    gap: $gap;
}

.summary-tile {
    @include FlexColumnLeft();
    justify-content: space-between;
    padding: $gap;
    background-color: var(--white);
    border-radius: $radius;
    font-size: 18px;
    font-family: 'Archivo', sans-serif;

    h2 {
        margin-top: 0px !important;
        margin-bottom: 2px !important;
    }

    // headings w icon and title
    .headings {
        @include FlexRowLeft();

        .icon-cover {
            @include FlexRowCenter();
            padding: 10px;
            width: fit-content;
            background-color: var(--lt-blue);
            color: var(--royal-blue);
            border-radius: 100%;
            margin-right: 15px;

            svg {
                // icon size 
                width: 22px;
                height: 22px;
            }
        }

        margin-bottom: 10px
    }

    // status comparison
    .status-display {
        @include FlexRowLeft();

        p {
            color: var(--blue-grey);
        }

        .ant-tag {
            @include FlexRowLeft();
            border: none;

            svg {
                margin-right: 2px;
            }
        }
    }
}