@import '../../App.scss';

.login-page {
  @include FlexRowCenter();
  background: url('../../assets/doctor-work.png');
  background-size: auto 100%;
  background-position: right;

}

// left section showing signup/login details
.login-section {
  display: flex;
  justify-content: center;
  width: 40vw;
  height: 100vh;
}

/// Logo styling, right illustration
.form__logo {
  @include FlexColumnCenter();
  width: 60vw;
  margin-bottom: 20px;
  color: var(--white);

  img {
    width: 100px;
  }
}