@import "../../App.scss";

.appt-card-root {
  // columns holding each piece of info
  .ant-col {
    @include FlexRowLeft();
  }

  

  .appt-card-main-details {
    padding: calc($gap/2) 12px;

    // status of the appointment 
    .appt-card-status {
      display: flex;

      .cancel-appt-btn {
        color: var(--cotton-candy);
        margin-left: 5px;
      }

      .complete-appt-btn {
        color: var(--cute-green);
      }

      .appt-card-status-tag,
      .status-change-btn {
        cursor: pointer;
      }
    }

    .ant-tag {
      margin: 0;
    }
  }

  // extra details when clicked on the accordion

  .appt-card-extra-details {
    padding: calc($gap/2) $gap;
    background-color: var(--dull-white);

    .extra-details-row {
      width: 100%;
      margin-bottom: 6px;
    }
  }
}