@import '../../App.scss';
@import '../SummaryTile/_SummaryTile.scss';

.appt-view-root {
  width: 100%;
  height: 100%;

  .context-info {
    color: var(--blue-grey);
    margin-top: 5px;
    flex-shrink: 0;
  }

  //wrapper of everything below title
  .appt-view-details {
    @include SubRightSection();

    // left section without calendar
    .appt-view-intro {
      @include FlexColumnLeft();
      height: 100%;
      overflow-y: auto;

      // the lich hen and buttons row
      .appt-view-function-display {
        @include FlexRowLeft();
        justify-content: space-between;

        // reload or function buttons
        .appt-view-function-button {
          width: fit-content;
          margin-left: 10px;
          border: none;
        }
      }

      // scrolling lists of appointments w headings
      .appt-view-wrapper {
        flex: 1 1 auto;
        background-color: var(--white);
        border-radius: $radius;

        // the labels of the columns
        .appt-list-title {
          padding: $gap 0;
          padding-left: 38.5px;
          padding-right: calc($gap);
        }

        .appt-view-list {
          overflow-y: auto;
        }

        // for notice text displays 
        .no-appt-notice {
          @include FlexColumnCenter();
         height: 50%;
        }

        // wrapper of antd collapse
        .ant-collapse {
          border-radius: 0;
          border-left: none;
          border-right: none;

          .ant-collapse-header {
            padding: 0;
            padding-left: calc($gap/2);
            border-radius: 0;
            background-color: var(--white);

            // arrow icon
            .ant-collapse-expand-icon {
              height: 100%;
              padding: 0;
              margin: auto;

              svg {
                width: 12px;
                color: var(--blue-grey);
              }
            }

            &:hover {
              background-color: var(--lt-blue);
            }
          }
        }
      }

      .ant-collapse-content {
        border-radius: 0;

        .ant-collapse-content-box {
          padding: 0;
          border-radius: 0;
        }
      }

      .ant-collapse-item {
        border-radius: 0;
        border-top: solid 2px var(--lt-grey);
        border-bottom: none
      }
    }

    // loading indicator
    .appt-view-loading {
      @include FlexColumnCenter();
      height: 100%;
      text-align: center;

      .appt-view-loading-indicator {
        margin: 30px;

        .ant-spin-dot-item {
          background-color: var(--blue-grey);
        }
      }

    }

  }

  // right column with calendar and serv
  .appt-view-date-picker {
    @include FlexColumnLeft();
    justify-content: flex-start;
    height: 100%;
    overflow-y: auto;

    /////////////////////////// calendar styling //////////////////////////////////////////////
    .ant-picker-calendar {
      background: transparent;
      flex: 0 0;
    }
    
    // styling for week days 
    .ant-picker-content {
      th {
        font-weight: 600;
        font-size: 13 !important;
      }

      // spacing between th and tbody
      tbody:before {
        content: "@";
        display: block;
        line-height: 15px;
        text-indent: -99999px;
      }
    }

    // STYLING FOR DATES
    .ant-picker-date-panel {

      // styling for all the cells
      .ant-picker-cell-inner {
        height: $cell-size;
        border-radius: 100%;

        .ant-picker-calendar-date-value {
          display: table-cell;
          line-height: $cell-size;
          min-width: $cell-size;
        }
      }

      // styling for selected cell
      .ant-picker-cell-selected {
        .ant-picker-calendar-date {
          text-align: center;
          background-color: var(--lt-blue) !important;
          height: $cell-size;
          min-width: $cell-size;

          .ant-picker-calendar-date-value {
            color: var(--royal-blue);
            font-weight: 600;
          }
        }

        // if selected date is today
        .ant-picker-calendar-date-today {
          background-color: var(--lt-green) !important;
        }
      }

      // styling for today's 
      .ant-picker-calendar-date-today {
        .ant-picker-calendar-date-value {
          color: var(--cute-green) !important;
          font-weight: 600;
        }

        &::before {
          border: none !important;
        }
      }
    }

    //STYLING FOR MONTHS 
    .ant-picker-month-panel {
      .ant-picker-cell-inner {
        height: $cell-size;
        line-height: $cell-size;
      }

      .ant-picker-cell-selected {
        .ant-picker-cell-inner {
          background-color: var(--lt-blue) !important;
          color: var(--royal-blue) !important;
          font-weight: 600;
        }
      }
    }

    // styling for selector
    .ant-picker-calendar-header {
      background-color: var(--dull-white);

      // styling for radio buttons
      .ant-picker-calendar-mode-switch {
        .ant-radio-button-wrapper {
          height: $cell-size;
          line-height: $cell-size;
          border: none;
          margin-left: 5px;
          border-radius: $radius;
          background: transparent;
          padding: 0 10px;

          span {
            color: var(--blue-grey);
            font-weight: 600;
          }

          // remove the border of the button
          &::before {
            width: 0;
          }
        }

        .ant-radio-button-wrapper-checked {
          .ant-radio-button-checked {
            background-color: var(--lt-blue);
            border-radius: $radius;
          }

          span {
            color: var(--royal-blue);
          }

          // remove the border of the button
          &::before {
            width: 0;
          }
        }

      }
    }
  }
}