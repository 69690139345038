@import "../../../App.scss";
@import "../Summary";

.summary-card-root {
  width: 100%;
  padding: $gap;
  border-top: solid 2px var(--lt-grey);
  border-top: solid 2px var(--lt-grey);

  &:hover {
    background-color: var(--lt-blue) !important;
    cursor: pointer;
    transition: all 300ms cubic-bezier(0.165, 0.84, 0.44, 1);
  }
}