@import '../../App.scss';

// login/signup form dual styling 
.login-form {
  width: 100%;
  height: 100%;
  margin: auto;
  padding: 0 21%;
  background-color: var(--white);
  @include FlexColumnLeft();

  // the title Dang Nhap/Dang Ky
  .login-title {
    margin-bottom: 10%;
  }
}

// form stuffs
.form {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;

  .form__info_head {
    margin-bottom: 20px;
  }
}

.form__control {
  margin-bottom: 1.5rem;

  // input styling
  input {
    border-radius: 5px;
    border: none;
    padding: 1em 1em;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--lt-blue-grey);
    font-family: 'Archivo', sans-serif;
    font-size: 15px;

    &:focus {
      outline-color : var(--royal-blue) !important;
    }
  }

  // input label
  .form__prompt {
    margin-bottom: 3px;
    color: var(--blue-grey);
  }

  button {
    margin-bottom: 10px;
  }

  // row holding button or switching text
  .form__button_row {
    font-size: $normal-body;
    
    /// swiching between sign in and sign up
    .form__signup_button {
      color: var(--royal-blue-ins);
    }  
  }

  
  label {
    display: flex;
    flex-flow: column nowrap;
  }

  // error text display
  .form__error {
    font-size: $small-body;
    margin: .2em 0;
    color: var(--cotton-candy);
  }

}
