@import '../../App.scss';
@import '../Summary/_Summary.scss';

.ps-sidebar-root {
  z-index: 100 !important;
  width: auto !important;
  min-width: auto !important;
}

.ps-menu-root {
  height: fit-content;
}

.sidebar_logo {
  @include FlexRowLeft();
  padding: $gap;
  margin-bottom: 10px;

  img {
    width: 60px;
    margin-right: 8px;
  }

  h2 {
    color: var(--royal-blue);
  }
}

// menu elements
.ps-menu-button {
  border-radius: 10px;
  margin: 0.2em 0.5em;
  height: 45px !important;

  &:hover {
    color: var(--black);
  }
}

.ps-menuitem-root {
  color: var(--blue-grey);
}

.ps-active.ps-menu-button {
  box-sizing: border-box;
  background-color: var(--lt-blue) !important;
  color: var(--royal-blue);
  font-weight: 600;
}

.ps-sidebar-container {
  background-color: var(--white) !important;
}

.ps-submenu-content {
  background-color: var(--dull-white) !important;
}