:root {
  --white: #ffffff;
  --grey-300: #eeeeee;
  --black: #000000;
  --blue: #559ffa;
  --blue-300: #64b5f6;
  --blue-400: #42a5f5;
  --blue-500: #42a5f5;
  --light-blue: #c6dbec;
  --red-100: #ffcdd2;
  --red: #ec0f25;

  // new color pallete
  --royal-blue-ins: #0077B6;
  --royal-blue: #0086cd;
  --blue-grey: #8CACCB;
  --lt-blue-grey: #f9fafc;
  --lt-blue-ins: #CCDDE8;
  --lt-blue: #E7F0F7;
  --dull-white: #F1F4F7;
  --white: #ffffff;
  --cotton-candy: #D4380D;
  --lt-candy: #fdeceb;
  --cute-green: #389E0D;
  --lt-green: #E7F7F5;
  --plain-grey: #a7a7a7;
  --lt-grey: #F0F0F0;
  --black: #000000;
  --lt-yellow: #FEFFE6;
  --yellow: #D4B106;
}

$small-body: 13px;
$normal-body: 15px;
$radius: 10px;
$tile-unit-height: 15vh;
$gap: 15px;
$cell-size: 25px;


// configs
@mixin FlexColumnCenter() {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

@mixin FlexColumnLeft() {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@mixin FlexRowCenter() {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin FlexRowLeft() {
  display: flex;
  align-items: center;
}

@mixin SubRightSection() {
  width: 100%;
  height: 90vh;
  padding: 0 $gap;
  margin: 0 !important;
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

* {
  font-family: 'Archivo', sans-serif !important;
  font-size: $normal-body;
}

.App {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: var(--dull-white);
  color: var(--black);
  letter-spacing: -0.4px;
}

main {
  margin: 0 auto;
  height: 100vh;
  position: relative;
}

button:hover {
  background-color: var(--grey-300);
}

// headings and texts
h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 36px;
  font-weight: 600;
  line-height: 36px;
}

h2 {
  font-size: 23px;
  font-weight: 600;
  line-height: 22px;
}


h4 {
  font-size: 18px;
  font-weight: 600;
  line-height: 16px;
}

h5 {
  font-size: 14px;
  font-weight: 600;
  height: fit-content;
}

// button stylings
// use regular buttons for bigger functions w stylings
button {
  font-size: $normal-body;
  width: fit-content;
  padding: 1em;
  font-weight: 600;
  border: none;
  cursor: pointer;
  font-family: 'Archivo', sans-serif;
  transition: all .5s ease;
  border-radius: 5px;
}

.primary-button {
  color: var(--white);
  background-color: var(--royal-blue);

  &:hover {
    color: var(--white) !important;
    background-color: var(--cute-green);
  }
}

.secondary-button {
  color: var(--royal-blue);
  background-color: var(--white);

  &:hover {
    color: var(--white) !important;
    background-color: var(--blue-grey);
  }
}

.dull-button {
  color: var(--blue-grey) !important;
  background-color: transparent !important;
  box-shadow: none;
  height: fit-content;
  border: none;
  padding: 0 auto;

  &:hover {
    color: var(--royal-blue) !important;
    background-color: transparent !important;
  }
}

// otherwise in dashboard just use antd, smaller buttons
.ant-btn-primary {
  color: var(--white);
  background-color: var(--royal-blue);

  &:hover {
    color: var(--white) !important;
    background-color: var(--cute-green) !important;
  }
}

.ant-btn-default {
  font-weight: 600;
  color: var(--royal-blue);
  background-color: var(--white);
  border-color: var(--lt-grey);

  &:hover {
    border-color: var(--lt-grey) !important;
    color: var(--cute-green) !important;
  }

  svg {
    transform: translateY(2px);
    margin-right: 5px;
  }
}

.danger-button {
  &:hover {
    color: var(--cotton-candy) !important;
  }
}


.ant-breadcrumb-link,
.ant-breadcrumb-separator {
  color: var(--blue-grey) !important;
}


.ant-breadcrumb {
  padding: 12px 0;
}

.ant-modal-footer {
  .ant-btn {
    width: fit-content;
  }
}

// just a slight different in displaying table rows
.any-other-div {
  background-color: var(--lt-blue-grey) !important;
}

.centered {
  @include FlexRowCenter();
}

.heading-div {
  margin-top: $gap;
  margin-bottom: 6px;

  button {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.ant-col {
  text-overflow: ellipsis;
  // gives ellipsis to all overflow text
  h5,
  p {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.ant-dropdown-menu-item-only-child {
  &:hover {
    background-color: transparent !important;
    cursor: default !important;
  }
}

.ant-picker {
  border: none !important;
  border-radius: $radius;
  background-color: transparent !important;

  input {
    font-weight: 600;
    color: var(--blue-grey) !important;
    &::placeholder {
      color: var(--blue-grey) !important;
    }
  }
  .ant-picker-suffix {
    display: none;
  }
  // close circle icon
  .ant-picker-clear, .ant-picker-suffix {
    background-color: var(--dull-white);
    color: var(--blue-grey) !important;
  }
}

.ant-picker-panel,
.ant-picker-date-panel,
.ant-picker-body {
  border-radius: $radius $radius $radius $radius !important;
  padding: 0 5px;
}

.ant-picker-panel {
  padding-top: 15px;
  padding-bottom: 5px;
}

// styling arrow of ant-picker
.ant-select-arrow {
  .anticon {
    transform: scale(0.8, 0.55);
    color: var(--blue-grey);
  }
}

// custom styling of ant-select
.ant-select {
  .ant-select-selector {
    border: none !important;
    border-radius: $radius;
    background-color: transparent !important;

    .ant-select-selection-item,
    .ant-select-selection-placeholder {
      @include FlexRowCenter();
      font-weight: 600;
      color: var(--blue-grey) !important;
      width: auto !important;
    }
  }
}