@import "../../App.scss";

.scheduler-root {
  @include SubRightSection();
  @include FlexColumnLeft();

  .scheduler-view {
    @include FlexColumnLeft();
    justify-content: flex-start;
    flex: 1 1 auto;
  }

}

.last-updated-text {
  justify-content: flex-end;
  color: var(--blue-grey);
}

.assignment-viewer-heading {
  @include FlexRowCenter();
  justify-content: space-between;

  .assignment-viewer-heading-title {
    @include FlexRowLeft();

    .scheduler-week-picker {
      margin: 5px 10px;

      input {
        cursor: pointer;
      }
    }

  }

  .assignment-viewer-heading-functions {
    @include FlexRowCenter();
  }

}