@import '../../App.scss';
@import '../SummaryTile/_SummaryTile.scss';

//title 
.tabbar-root {
    @include FlexRowLeft();
    min-height: 7vh;
    max-height: 7vh;
    padding: 0 calc(2*$gap);
    background-color: var(--white);
}

.tabbar-heading {
    @include FlexRowCenter();
}

.tabbar-user-info {
    @include FlexRowCenter();
    margin-left: auto;

    h4 {
        margin-right: 5px;
    }
}