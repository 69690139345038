@import "../../App.scss";

.service-card {
  @include FlexColumnLeft();
  justify-content: flex-start;
  flex: 1 0 auto;

  .service-card-title {
    @include FlexRowCenter();
    justify-content: space-between;
  }

  .service-card-legend {
    padding: $gap;
  }

  // displaying all the cards and titles
  .service-card-wrapper {
    flex: 1 1 auto;
    background-color: var(--white);
    border-radius: $radius;
    box-sizing: border-box;


    .service-card-delete {
      transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
      &:hover {
        color: var(--cotton-candy);
        cursor: pointer;
      }
    }

    .service-card-edit {
      transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
      &:hover {
        color: var(--cute-green);
        cursor: pointer;
      }
    }

    .service-card-checkbox {
      :hover {
        cursor: default;
      }
    }
  }

  // holding all the cards
  .service-card-list {
    padding: 0 $gap;

    // styling of a card
    .service-card-root {
      padding: calc($gap/2) 0;
    }
  }
}