@import '../../App.scss';

.dept-signup-form {
  @include FlexColumnLeft();
  width: 100%;
  padding-top: $gap;
  .form__buttons {
    display: flex;
    justify-content: flex-end;
  }
}