@import '../../App.scss';

.summary-root {
  @include SubRightSection();

  .summary-intro {
    @include FlexColumnLeft();
    height: 100%;
    overflow-y: auto;

    // heading of the dept summary
    .summary-dept-heading {
      @include FlexRowCenter();
      justify-content: space-between;
    }

    // label of department summary list
    .summary-dept-label {
      padding: $gap;
      border-bottom: 1.4px solid var(--lt-grey);
    }

    // wrapper of department summary
    .summary-dept {
      @include FlexColumnLeft();
      height: 100%;
      width: 100%;
      flex: 1 1 auto;
      background-color: var(--white);
      border-radius: $radius;


      .dept-cards-wrapper {
        flex: 1 1 auto;
        overflow-y: auto;
      }
    }

    // row holding summary tiles
    .summary-tiles {
      margin-bottom: 12px;
    }
  }

}