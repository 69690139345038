@import "../../../App.scss";

$slot-height: 70px;

// the whole assignment board wrapper
.assignment-schedule-ctr {
  width: 100%;
  background-color: var(--lt-grey);
  border-radius: $radius;
  flex: 1 1 auto;
  overflow-y: auto;

  .assignment-schedule-view {
    @include FlexColumnCenter();
    width: 100%;
    height: 100%;

    // just the header row
    .assignment-header {
      width: 100%;
      border-bottom: 4px solid var(--lt-grey);
      background-color: var(--white);

      .assignment-header-col {
        height: auto;
        padding: $gap;

        h5 {
          overflow: visible;
        }
      }
    }

    // empty notice
    .assignment-content {
      width: 100%;
      height: 100%;
      flex: 1 1 auto;

      .assignment-content-empty-notice {
        @include FlexColumnCenter();
        height: 100%;

        .assignment-content-empty-icon {
          margin: 10px;
        }
      }
    }


    // the contents (whole detail row)
    .assignment-doctor-tile {
      border-bottom: 4px solid var(--lt-grey);
      background-color: var(--white);

      .assignment-doctor-label {

        .assignment-doctor-row {
          @include FlexRowCenter();
          height: $slot-height;
          border-right: 1px solid var(--lt-grey);
          border-left: none;
        }
      }

      .assignment-morning {
        color: var(--yellow);
      }

      .assignment-afternoon {
        color: var(--royal-blue);
      }

      // the checking labels in doc schedule
      .day-checker {

        // LABEL FOR CHECKBOX (CUSTOMIZED)
        .assignment-col-label {
          display: block;
          position: relative;
          cursor: pointer;
          width: 100%;
          height: calc($slot-height/2);
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;

          input {
            opacity: 0; // instead of display:none
            position: absolute;
            top: 0;
            left: 0;
            height: calc($slot-height/2);
            width: 100%;
            z-index: 20; // or abov
            cursor: pointer;

            &:checked~.custom-checkmark {
              background-color: var(--lt-blue);
              color: var(--royal-blue);
              opacity: 1;
            }

            &:checked~.custom-checkmark-morning {
              background-color: var(--lt-yellow);
              color: var(--yellow);
              content: none;
            }
          }

          &:hover {
            input~.custom-checkmark {
              background-color: var(--lt-grey);
              opacity: 1;
            }
          }

          .custom-checkmark {
            @include FlexRowCenter();
            position: absolute;
            opacity: 0;
            top: 1px;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            height: calc($slot-height/2 - 2px);
            width: 98%;
            transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
            border-radius: $radius;
            color: var(--black);
            font-weight: 600;
          }

        }
      }

      .assignment-col-delete-btn {
        cursor: pointer;

        &:hover {
          color: var(--cotton-candy);
          transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
        }
      }
    }


  }

  // rows and columns config
  .assignment-col {
    @include FlexColumnCenter();
    height: $slot-height;
    border-left: 1px solid var(--lt-grey);
    border-right: 1px solid var(--lt-grey);
  }

  .assignment-sub-row {
    @include FlexRowCenter();
    height: 50%;
    width: 100%;
  }

  .assignment-sub-col {
    @include FlexColumnCenter();
    height: 50%;
  }
}